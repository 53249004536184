enum StripeKey {
    PROD = 'pk_live_zePw0OIxuGTGyRKZTQajwZC300jMkZoMaN',
    TEST = 'pk_test_QS456dIU4P88z1IjEnnoSdFc00xtasl8s6',
}
enum NewStripeKey {
    PROD = 'pk_live_51MqwMLA0lH2qAVC2TCDftKcuFCfXnTrp7Zk60cpgSqh3tqa3DuFyiXBCzCcr4ygoNcSwztd3PdVFQKjMXDcNcAiF00X1KxOq1L',
    TEST = 'pk_test_51MqwMLA0lH2qAVC2YtM3q2Pi3Le7pivWEO7SI2TE4s53HpT05CxofK364pBVAnZPYs48DhIQbYTRruV28QCEgQrA000II3VXX2',
}

const stage = process.env.GATSBY_STAGE;
export const stripeId = stage === 'prod' ? NewStripeKey.PROD : NewStripeKey.TEST;
export const oldStripeId = stage === 'prod' ? StripeKey.PROD : StripeKey.TEST;

const PLANS = {
    prod: {
        GYM: 'price_1HDUWrCKeRUZHadOUq2f1Ubx',
        HOME: 'price_1HDUVyCKeRUZHadO1az34Oho',
    },
    qa: {
        GYM: 'price_1HDUTTCKeRUZHadOLn9Iq6bD',
        HOME: 'price_1HDUSzCKeRUZHadOFgY22kW8',
    },
};

const plan = stage === 'prod' ? PLANS.prod : PLANS.qa;

export const StripePlan: {
    HOME: string;
    GYM: string;
} = plan;

export const NUTRITION_PLAN_LEGACY = {
    id: 'nutrition_hub',
    name: 'Nutrition Hub',
    description: 'Nutrition Hub',
    price: 109,
    recurring: false,
    plan: {
        qa: 'price_1Mue2fA0lH2qAVC2zGtSBFIw',
        prod: 'price_1MusfBA0lH2qAVC2kCgsUZkS',
    },
};

export const NUTRITION_PLAN = {
    id: 'nutrition_hub',
    name: 'Nutrition Hub',
    description: 'Nutrition Hub',
    price: 119,
    recurring: false,
    plan: {
        qa: 'price_1OZQL6A0lH2qAVC2q4uwZIp4',
        prod: 'price_1OZQJNA0lH2qAVC24fuFjYQy',
    },
};
